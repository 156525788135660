import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Content from '@/browser/Content';

/**
 * Container styled-component for setting maximum width and margin.
 */
const Container = styled.div`
  max-width: 720px;
  width: 95%;
  margin: 0 auto;
`;

/**
 * Section styled-component with background color and padding.
 *
 * @param {Object} props - Component properties.
 */
const Section = styled.div`
  background-color: #fff;
  padding: ${(props) => (props.$afterHero ? '60px 0' : '0')};
`;

/**
 * Wysiwyg component for rendering rich text content with OneTrust integration.
 *
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} React component.
 */
const Wysiwyg = (props) => {
  // If no content provided, return null.
  if (!props.content) {
    return null;
  }

  /**
   * Effect hook to reinitialize OneTrust components after a delay.
   */
  useEffect(() => {
    const initializeOneTrust = () => {
      if (typeof window !== 'undefined' && window.OneTrust) {
        // Reinitialize OneTrust cookie policy HTML.
        window.OneTrust.initializeCookiePolicyHtml();

        // Custom OneTrust button for editing cookie settings.
        const consentButtons = document.querySelectorAll(
          '.kuluttaja-ot-sdk-show-settings'
        );
        if (consentButtons) {
          consentButtons.forEach((button) => {
            // Get text from data attribute or use default.
            const text =
              button.getAttribute('data-text') || 'Muokkaa evästeasetuksia';
            button.innerHTML = text;

            // Add event listener to show OneTrust settings.
            button.addEventListener('click', () => {
              window.OneTrust.ToggleInfoDisplay();
            });
          });
        }
      }
    };

    // Delayed execution to allow the page to load.
    setTimeout(initializeOneTrust, 1500);
  }, []);

  return (
    <Section $afterHero={props.previousSection === 'Bodybuilder_HeroUnit'}>
      <Container>
        <Content html={props.content} />
      </Container>
    </Section>
  );
};

// Prop type validation for content.
Wysiwyg.propTypes = {
  content: PropTypes.string
};

export default Wysiwyg;
