import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from '@/components/Buttons/Button';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getProductDataById } from './PrisjaktUtil';

/**
 * Styled button for the PrisjaktLink component.
 */
const PrisjaktButton = styled(StyledButton)`
  display: inline-flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  svg {
    padding-top: 0;
  }
`;

/**
 * PrisjaktLink component that renders a button with a link to a Prisjakt product page.
 * @param {Object} props - The component's props.
 * @param {string} props.productId - The ID of the product.
 * @param {React.ReactNode} props.children - Content inside the button.
 * @param {string} props.buttonstyle - Style for the button (default: 'bordered').
 * @param {string} props.size - Size of the button (default: 'small').
 * @param {Object} props.prisjaktData - Prisjakt data containing product information.
 */
const PrisjaktLink = (props) => {
  const { productId, children, buttonstyle, size, prisjaktData } = props;

  // Get product data by ID from Prisjakt data
  const product = getProductDataById(productId, prisjaktData);

  // If the product doesn't exist or no offers, return null
  if (!product || product?.offers?.length === 0) {
    return null;
  }

  // Get the Prisjakt product page URL, or null if the product doesn't exist
  const prisjaktPageUrl = product ? product.productPage : null;

  return (
    <PrisjaktButton
      $buttonstyle={buttonstyle || 'bordered'}
      $size={size || 'small'}
      to={prisjaktPageUrl}
    >
      {children}
    </PrisjaktButton>
  );
};

/**
 * Prop types for the PrisjaktLink component.
 */
PrisjaktLink.propTypes = {
  children: PropTypes.any,
  productId: PropTypes.string.isRequired,
  prisjaktData: PropTypes.object,
  buttonstyle: PropTypes.string,
  size: PropTypes.string
};

/**
 * Connects the PrisjaktLink component to the Redux store and provides it with Prisjakt data.
 */
export default connect((state) => ({ prisjaktData: state.prisjakt }))(
  PrisjaktLink
);
