import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Money from '@/browser/Money';
import Link from '@/components/Link/Link';
import { getProductDataById } from './PrisjaktUtil';
import Tooltip from '@/components/Tooltip/Tooltip';

/**
 * Styled component for displaying a small informational message.
 */
const SmallMessage = styled.div`
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.colors.grey};
`;

/**
 * Styled wrapper for individual offer items.
 */
const OfferItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

/**
 * Styled component for individual offer items.
 */
const OfferItem = styled(Link)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
  background: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[10]};
  padding: 8px;
  font-size: 12px;
  letter-spacing: 1.5px;
  font-family: ${(props) => props.theme.fonts.secondary};
  transition: all 350ms;
  outline: 2px solid
    ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[10]};
  outline-offset: -2px;

  &:hover {
    outline-color: rgba(255, 255, 255, 1);
    transition: all 50ms;
    box-shadow: 0 0 14px 0 rgba(87, 87, 87, 0.36);
  }

  img {
    max-width: 65px;
    margin-right: auto;
  }
`;

/**
 * Styled component for displaying the title of the best prices section.
 */
const OfferTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  letter-spacing: 1.4px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grey};
  text-align: center;
  margin-bottom: 5px;
`;

/**
 * PrisjaktOfferWidget component that displays a list of offers for a product.
 * @param {Object} props - The component's props.
 * @param {string} props.productId - The ID of the product.
 * @param {Object} props.prisjaktData - Prisjakt data containing product information.
 */
const PrisjaktOfferWidget = ({ productId, prisjaktData }) => {
  const product = getProductDataById(productId, prisjaktData);

  if (!product || !product.offers || product.offers.length === 0) {
    return (
      <div>
        <SmallMessage>
          <FormattedMessage id="productReview.text.noUpdatedPrice" el="span" />
        </SmallMessage>
      </div>
    );
  }

  const { offers } = product;

  return (
    <>
      <OfferTitle>
        <FormattedMessage id="productReview.text.bestPrices" tagName="div" />
        <FormattedMessage
          id="productReview.text.bestPricesTooltip"
          tagName="div"
        >
          {(text) => <Tooltip content={text[0]} />}
        </FormattedMessage>
      </OfferTitle>
      <OfferItemWrapper>
        {offers.map(
          (offer, index) =>
            index < 3 && (
              <OfferItem key={index} to={offer.url}>
                <img
                  src={offer.shop.logo.small}
                  alt={offer.shop.name}
                  title={offer.shop.name}
                />
                <div>
                  <strong>
                    <Money value={offer.price.value} digits={2} />
                  </strong>
                </div>
              </OfferItem>
            )
        )}
      </OfferItemWrapper>
    </>
  );
};

/**
 * Prop types for the PrisjaktOfferWidget component.
 */
PrisjaktOfferWidget.propTypes = {
  productId: PropTypes.string,
  prisjaktData: PropTypes.object
};

/**
 * Default props for the PrisjaktOfferWidget component.
 */
PrisjaktOfferWidget.defaultProps = {
  productId: '',
  prisjaktData: {}
};

/**
 * Connects the PrisjaktOfferWidget component to the Redux store and provides it with Prisjakt data.
 */
export default connect((state) => ({ prisjaktData: state.prisjakt }))(
  PrisjaktOfferWidget
);
