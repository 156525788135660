import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PrisjaktLink from '@/components/Prisjakt/PrisjaktLink';
import PrisjaktPrice from '@/components/Prisjakt/PrisjaktPrice';
import CloseButton from '@/components/Buttons/CloseButton';
import WpImage from '@/components/Images/WpImage';
import ScoreStars from '@/components/ScoreStars/ScoreStars';
import MetaValueProtection from './MetaValueProtection';
import { FormattedMessage } from 'react-intl';
import {
  getOverallScore,
  getMetaValue,
  getStars
} from '@/containers/Test/product-review';
import ComparisonRowMobile from './ComparisonRowMobile';

/**
 * Styled component for a comparison review item column (mobile).
 */
const CompCol = styled.div`
  width: 100%;
  background-color: #fff;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
`;

/**
 * Styled component for the product image.
 */
const ProductImage = styled(WpImage)`
  max-width: 262px;
  min-width: 82px;
  width: 100%;
  height: 120px;
  object-fit: contain;
  object-position: center center;
  margin-bottom: 16px;
  ${(props) => props.theme.media.mobile`
    max-width: inherit;
    width: 100%;
    min-height: 150px;
  `};
`;

/**
 * Styled component for the model label.
 */
const ModelLabel = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 12px;
`;

/**
 * Styled component for the model brand label.
 */
const ModelBrandLabel = styled.div`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 10px;
  letter-spacing: 1.5px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

/**
 * Styled component for mobile price.
 */
const MobilePrice = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
  text-align: center;
  margin: 12px auto;
`;

/**
 * Styled component for product information.
 */
const ProductInfo = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  padding: 20px 8px;
`;

/**
 * Styled component for a test badge container.
 */
const TestBadgeContainer = styled.div`
  display: flex;
  flex: auto;
  height: 104px;
  flex-direction: column;
  justify-content: center;
  padding: 20px 8px 18px;
`;

/**
 * Styled component for the score container.
 */
const ScoreContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  border-bottom: 2px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
`;

/**
 * Styled component for the score label.
 */
const ScoreLabel = styled.span`
  font-size: 12px;
`;

/**
 * Styled component for the score value.
 */
const Score = styled.span`
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.gunmetal};
  font-weight: bold;
  font-size: 32px;
`;

/**
 * Styled component for an Remove button.
 */
const Remove = styled(CloseButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`;

/**
 * Styled component for the Prisjakt link wrapper.
 */
const PrisjaktLinkWrapper = styled.div`
  padding: 11px 8px;
  border-bottom: 2px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};

  a {
    width: 100%;
  }
`;

/**
 * Styled component for an icon image.
 */
const IconImg = styled.img`
  display: block;
  margin: 0 auto;
  height: 64px;
  width: 92px;
  object-fit: contain;
  object-position: top;
`;

/**
 * Arrow function component for a comparison review item on mobile.
 * @param {Object} props - The component's props.
 */
const ComparisonReviewItemMobile = ({
  product,
  accessible,
  onRemove,
  compareTree,
  references,
  reference_descriptions,
  singleProductPage,
  index
}) => {
  const origScoreVal = parseFloat(getOverallScore(product));
  const scoreVal = Math.round(origScoreVal);

  // Display default price
  const defaultPrice = getMetaValue('hinta', product);

  return (
    <CompCol className={`col-${product.id}`}>
      <ProductInfo>
        <ProductImage image={product.featuredImage} />
        <ModelBrandLabel>{getMetaValue('Merkki', product)}</ModelBrandLabel>
        <ModelLabel>{getMetaValue('Malli', product)}</ModelLabel>
        <ScoreStars score={getStars(origScoreVal)} />
      </ProductInfo>
      <MobilePrice>
        <PrisjaktPrice
          productId={product.hintaopas_id}
          defaultPrice={defaultPrice}
        />
      </MobilePrice>
      <ScoreContainer>
        <ScoreLabel>
          <FormattedMessage id="productReview.text.grade" />
        </ScoreLabel>
        <Score>
          <MetaValueProtection column={{ restricted: isNaN(scoreVal) }}>
            {scoreVal}
          </MetaValueProtection>
        </Score>
      </ScoreContainer>
      <PrisjaktLinkWrapper>
        <PrisjaktLink productId={product.hintaopas_id} buttonstyle="normal">
          <FormattedMessage id="productReview.text.comparePrices" />
        </PrisjaktLink>
      </PrisjaktLinkWrapper>
      <TestBadgeContainer>
        {accessible &&
          Array.isArray(product.product_review_categories) &&
          product.product_review_categories.map((category, categoryIndex) => (
            <IconImg
              key={categoryIndex}
              src={category.icon.sourceUrl}
              alt={category.icon.altText}
            />
          ))}
      </TestBadgeContainer>
      {!singleProductPage && (
        <Remove onClick={() => onRemove(product)} type="button" />
      )}
      {compareTree.map((row, rowIndex) => (
        <ComparisonRowMobile
          key={`row-${row.name}-${rowIndex}`}
          row={row}
          children={row.children}
          product={product}
          references={references}
          reference_descriptions={reference_descriptions}
          index={index}
        />
      ))}
    </CompCol>
  );
};

/**
 * Prop types for the ComparisonReviewItemMobile component.
 */
ComparisonReviewItemMobile.propTypes = {
  product: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  compareTree: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  references: PropTypes.array,
  reference_descriptions: PropTypes.array,
  singleProductPage: PropTypes.bool
};

/**
 * Default props for the ComparisonReviewItemMobile component.
 */
ComparisonReviewItemMobile.defaultProps = {
  onRemove: () => {}
};

export default ComparisonReviewItemMobile;
