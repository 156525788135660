import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import logo from './logo_light.svg';

const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 15;
  padding: 24px 8px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;

  img {
    float: right;
    width: auto;
    height: 17px;
  }
`;

const PreviousButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.kuluttaja2021.tyrni[20]};
`;

const CodeScannerTopBar = ({ clickPrevious }) => {
  return (
    <TopBar>
      <PreviousButton onClick={clickPrevious}>
        <FormattedMessage id="codeScanner.previous" />
      </PreviousButton>
      <img src={logo} className="Header-logo" alt="logo" />
    </TopBar>
  );
};

CodeScannerTopBar.propTypes = {
  clickPrevious: PropTypes.func.isRequired
};

export default CodeScannerTopBar;
