import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PrisjaktLink from '@/components/Prisjakt/PrisjaktLink';
import PrisjaktPrice from '@/components/Prisjakt/PrisjaktPrice';
import CloseButton from '@/components/Buttons/CloseButton';
import WpImage from '@/components/Images/WpImage';
import ScoreStars from '@/components/ScoreStars/ScoreStars';
import MetaValueProtection from './MetaValueProtection';
import { FormattedMessage } from 'react-intl';
import {
  getOverallScore,
  getMetaValue,
  getStars
} from '@/containers/Test/product-review';

/**
 * Styled component for a comparison review item column.
 */
const CompCol = styled.div`
  min-width: 184px;
  max-width: 184px;
  background-color: #fff;
  border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  border-style: solid;
  border-width: 2px 2px 2px 0;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  &:first-of-type {
    border-left-width: 2px;
    min-width: 186px;
    max-width: 186px;
  }
  transform: translateX(-2px);
  box-sizing: border-box;
  > *:nth-last-child(2) {
    border-bottom: 0 none;
  }
`;

/**
 * Styled component for the product image.
 */
const ProductImage = styled(WpImage)`
  max-width: 262px;
  min-width: 82px;
  width: 100%;
  height: 120px;
  object-fit: contain;
  object-position: top center;
  margin-bottom: 16px;
  ${(props) => props.theme.media.mobile`
    max-width: inherit;
    width: 100%;
    min-height: 150px;
  `};
`;

/**
 * Styled component for the model label.
 */
const ModelLabel = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 16px;
  min-height: 32px;
  color: ${(props) => props.theme.colors.gunmetal};
`;

/**
 * Styled component for the manufacturer.
 */
const Manufacturer = styled.div`
  color: ${(props) => props.theme.colors.grey};
  margin-bottom: 8px;
`;

/**
 * Styled component for product information.
 */
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 8px;
  border-bottom: 2px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
`;

/**
 * Styled component for the score container.
 */
const ScoreContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
`;

/**
 * Styled component for the score label.
 */
const ScoreLabel = styled.span`
  font-size: 12px;
`;

/**
 * Styled component for the score value.
 */
const Score = styled.span`
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.gunmetal};
  font-weight: bold;
  font-size: 32px;
`;

/**
 * Styled component for the remove button.
 */
const Remove = styled(CloseButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`;

/**
 * Styled component for prices.
 */
const Prices = styled.div`
  margin-top: 10px;
  min-height: 128px;
  display: flex;
  flex-direction: column;
`;

/**
 * Styled component for a price.
 */
const Price = styled.div`
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 17px;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.colors.gunmetal};
`;

/**
 * Styled component for an icon image.
 */
const IconImg = styled.img`
  position: static;
  margin: 16px auto;
  height: 64px;
  width: 92px;
  object-fit: contain;
  object-position: top;
`;

/**
 * Arrow function component for a comparison review item.
 * @param {Object} props - The component's props.
 */
const ComparisonReviewItem = ({
  product,
  singleProductPage,
  accessible,
  onRemove
}) => {
  const origScoreVal = parseFloat(getOverallScore(product));
  const scoreVal = Math.round(origScoreVal);

  // Display default price
  const defaultPrice = getMetaValue('hinta', product);

  return (
    <CompCol className={`col-${product.id}`}>
      <ProductInfo>
        <ProductImage image={product.featuredImage} />
        <Manufacturer>{getMetaValue('Merkki', product)}</Manufacturer>
        <ModelLabel>{getMetaValue('Malli', product)}</ModelLabel>
        <ScoreStars score={getStars(origScoreVal)} />
      </ProductInfo>
      <ScoreContainer>
        <ScoreLabel>
          <FormattedMessage id="productReview.text.grade" />
        </ScoreLabel>
        <Score>
          <MetaValueProtection column={{ restricted: isNaN(scoreVal) }}>
            {scoreVal}
          </MetaValueProtection>
        </Score>
      </ScoreContainer>
      <Prices>
        <Price>
          <PrisjaktPrice
            productId={product.hintaopas_id}
            defaultPrice={defaultPrice}
          />
        </Price>
        <p>
          <PrisjaktLink productId={product.hintaopas_id} buttonstyle="normal">
            <FormattedMessage id="productReview.text.comparePrices" />
          </PrisjaktLink>
        </p>
      </Prices>
      {!singleProductPage && (
        <Remove onClick={() => onRemove(product)} type="button" />
      )}
      {accessible &&
        Array.isArray(product.product_review_categories) &&
        product.product_review_categories.map((category, index) => (
          <IconImg
            key={index}
            src={category.icon.sourceUrl}
            alt={category.icon.altText}
          />
        ))}
    </CompCol>
  );
};

/**
 * Prop types for the ComparisonReviewItem component.
 */
ComparisonReviewItem.propTypes = {
  product: PropTypes.object.isRequired,
  singleProductPage: PropTypes.bool,
  accessible: PropTypes.bool,
  onRemove: PropTypes.func
};

export default ComparisonReviewItem;
