import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fetch from 'node-fetch';
import Loader from '@/components/Loader/Loader';
import ErrorHandler from '@/components/ErrorHandler/ErrorHandler';
import config from '@/config';

/**
 * REST API query wrapper to handle loading & errors in a generic way
 * @param {Object} props - Component props
 * @param {string} props.path - The API path to fetch data from
 * @param {boolean} [props.hide_loader] - Flag to hide the loader
 * @param {Function} [props.children] - Render function that receives data and props
 * @param {Function} [props.onCompleted] - Callback function called when data fetching is completed
 * @param {boolean} [props.alwaysRender] - Flag to force rendering even if data is empty
 * @param {boolean} [props.fetchmore] - Flag to fetch more data
 * @param {boolean} [props.small] - Flag to use a small loader
 */
const RestQueryHandler = ({
  path,
  hide_loader,
  children = () => {},
  onCompleted = () => {},
  alwaysRender = false,
  fetchmore = false,
  small
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  /**
   * Determines whether to render the component based on data and alwaysRender flag
   * @param {*} data - The data to check for rendering
   * @returns {boolean} - Whether to render the component
   */
  const shouldRender = (data) => {
    if (alwaysRender) {
      return true;
    }
    if (typeof data === 'undefined' || data === null) {
      return false;
    }
    return (
      (Array.isArray(data) && data.length > 0) || Object.keys(data).length > 0
    );
  };

  /**
   * Fetches data from the specified API path
   */
  const fetchData = async (abortController) => {
    setLoading(true);

    const args = abortController ? { signal: abortController.signal } : {};

    try {
      const response = await fetch(`${config.app.apiProxyHost}${path}`, args);
      const responseData = await response.json();

      const output =
        fetchmore && data?.length ? data.concat(responseData) : responseData;

      setData(output);
      setLoading(false);
      onCompleted(responseData);
    } catch (error) {
      // Only set error if request was not aborted
      if (!abortController.signal.aborted) {
        setError(error);
      }
      setLoading(false);
    }
  };

  /**
   * Fetch data on component mount and when API path changes
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchData(abortController);

    // Cleanup function on component unmount
    return () => {
      abortController.abort();
    };
  }, [path]);

  return (
    <>
      {error && <ErrorHandler error={error} />}
      {shouldRender(data)
        ? children(data, { refetch: fetchData, loading })
        : !loading && children(data, { refetch: fetchData, loading })}
      {!hide_loader && <Loader visible={loading} small={small} />}
    </>
  );
};

RestQueryHandler.propTypes = {
  path: PropTypes.string.isRequired,
  hide_loader: PropTypes.bool,
  children: PropTypes.func,
  onCompleted: PropTypes.func,
  alwaysRender: PropTypes.bool,
  fetchmore: PropTypes.bool,
  small: PropTypes.bool
};

export default RestQueryHandler;
