/**
 * This is a fragment of the "BodyBuilder" Test Article block.
 */

import gql from 'graphql-tag';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_TEST_ARTICLE = gql`
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment CommentModel on Comment {
    id
    commentId
    approved
    dateGmt
    content
    author {
      node {
        ... on User {
          name
          email
          firstName
          lastName
        }
      }
    }
  }

  fragment TestArticle on Bodybuilder_TestArticle {
    test_article {
      ...ContentNodeBasicModel
      title
      postId
      slug
      excerpt
      content
      commentStatus
      author {
        node {
          id
          name
        }
      }
      featuredImage {
        node {
          ...FeaturedImageModel
          dateGmt
          description
        }
      }
      extra {
        picture_author
        article_author
      }
      comments {
        nodes {
          ...CommentModel
          replies {
            nodes {
              ...CommentModel
              replies {
                nodes {
                  ...CommentModel
                }
              }
            }
          }
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      categories(where: { childless: true }) {
        nodes {
          slug
          name
          posts(where: { orderby: { field: DATE, order: ASC } }) {
            nodes {
              ...ContentNodeBasicModel
              title
              slug
              paywall {
                restricted
                accessible
                products {
                  title
                  description
                  price
                  price_unit
                  link
                  uri
                  featuredImage {
                    node {
                      ...FeaturedImageModel
                      slug
                      uri
                    }
                  }
                }
              }
              featuredImage {
                node {
                  ...FeaturedImageModel
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FRAGMENT_TEST_ARTICLE;
