/**
 * This is the query to get the posts for main navigation.
 */

import gql from 'graphql-tag';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const QUERY_GET_POSTS_FOR_NAVIGATION = gql`
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  query getPostsForNavigation($termId: ID!) {
    posts(
      first: 3
      where: { categoryIn: [$termId], orderby: [{ field: DATE, order: DESC }] }
    ) {
      nodes {
        ...ContentNodeBasicModel
        title
        slug
        paywall {
          premium
          accessible
        }
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            ...FeaturedImageModel
          }
        }
      }
    }
  }
`;

export default QUERY_GET_POSTS_FOR_NAVIGATION;
