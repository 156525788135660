import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Link from '../Link/Link';
import _get from 'lodash.get';

const buttonColor = (props) =>
  props.$color
    ? _get(props.theme.colors, props.$color)
    : props.theme.colors.kuluttaja2021.tummaPetroli.full;
const buttonTxtColor = (props) =>
  props.$txtcolor ? _get(props.theme.colors, props.$txtcolor) : 'white';

const buttonStyles = css`
  text-decoration: none;
  background-color: ${(props) =>
    props.$buttonstyle === 'bordered' ? 'transparent' : buttonColor};
  color: ${(props) =>
    props.$buttonstyle === 'bordered' && !props.$txtcolor
      ? buttonColor
      : buttonTxtColor};
  min-height: ${(props) => (props.$size === 'small' ? '36px' : '56px')};
  min-width: ${(props) => (props.$size === 'small' ? '100px' : '190px')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1;
  font-size: ${(props) => (props.$size === 'small' ? '11px' : '14px')};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 800;
  padding: ${(props) =>
    props.$size === 'small' ? '12px 16px 12px' : '20px 32px 20px'};
  text-align: center;
  transition: all 350ms;
  outline: ${(props) => (props.$size === 'small' ? '2px' : '3px')} solid
    ${(props) =>
      props.$buttonstyle === 'bordered' ? buttonColor : 'rgba(255,255,255,0)'};
  outline-offset: ${(props) => (props.$size === 'small' ? '-2px' : '-3px')};
  box-shadow: 0 0 0 0 rgba(87, 87, 87, 0.36);
  &:hover {
    outline-color: rgba(255, 255, 255, 1);
    color: ${(props) =>
      props.$buttonstyle === 'bordered' && props.$color === 'white'
        ? props.theme.colors.kuluttaja2021.tummaPetroli.full
        : buttonTxtColor};
    box-shadow: 0 0 14px 0 rgba(87, 87, 87, 0.36);
    transition: all 50ms;
    background-color: ${buttonColor};
  }
  opacity: ${(props) => (props.$disabled || props.disabled ? '0.3' : '1')};
  ${(props) => props.$disabled || (props.disabled && 'pointer-events: none;')};
  ${(props) =>
    props.$size !== 'small' &&
    props.theme.media.laptop`
    padding-left: 22px;
    padding-right: 22px;
  `}
`;

const buttonStylesPlain = css`
  text-decoration: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary};
  height: 56px;
  display: inline-block;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 800;
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    transition: all 50ms;
  }
`;

const StyledButton = styled(Link)`
  ${(props) =>
    props.$buttonstyle === 'plain' ? buttonStylesPlain : buttonStyles};
`;

const NormalButton = styled.button`
  ${(props) =>
    props.$buttonstyle === 'plain'
      ? buttonStylesPlain
      : buttonStyles} border: none;
  padding-top: 0;
  padding-bottom: 0;
`;

const ActionButton = (props) => {
  if (
    !props ||
    props.action === null ||
    (props.action.url === null && props.action.title === null)
  ) {
    return null;
  }
  const { action, color, txtColor, buttonstyle, ...rest } = props;
  return (
    <StyledButton
      to={action.url}
      target={action.target}
      $color={color}
      $txtcolor={txtColor}
      $buttonstyle={buttonstyle}
      {...rest}
    >
      {action.title}
    </StyledButton>
  );
};

export { StyledButton, ActionButton, NormalButton };

ActionButton.propTypes = {
  action: PropTypes.object,
  color: PropTypes.string
};

ActionButton.defaultProps = {
  action: null
};

const ga_data = {
  event: 'button_click',
  prefix: 'button'
};

StyledButton.propTypes = {
  ga_data: PropTypes.object
};
StyledButton.defaultProps = {
  ga_data
};
NormalButton.propTypes = {
  ga_data: PropTypes.object
};
NormalButton.defaultProps = {
  ga_data
};
