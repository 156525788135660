import React from 'react';
import styled from 'styled-components';
import Link from '../../components/Link/Link';
import logo from './logo.svg';

const LogoContainer = styled.div`
  flex: 1;
  max-width: 270px;
  padding-right: 38px;
  ${(props) => props.theme.media.tablet`
    max-width: inherit;
    padding-right: 0;
    width: auto;
  `};
  img {
    ${(props) => props.theme.media.tablet`
      max-width: 100px;
    `} margin-bottom: 5px;
  }
`;
const LogoSlogan = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  ${(props) =>
    props.$isSticky
      ? `
          display: none;
        `
      : props.theme.media.laptop`
    display: none;
  `};
`;

const Logo = ({ isSticky, ...rest }) => {
  return (
    <LogoContainer {...rest}>
      <Link to="/">
        <img src={logo} className="Header-logo" alt="logo" />
        <LogoSlogan $isSticky={isSticky}>Suomen suurin testaaja.</LogoSlogan>
      </Link>
    </LogoContainer>
  );
};

export default Logo;
