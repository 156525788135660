/**
 * This is the query to get Magazine's main articles from posts and pages.
 */

import gql from 'graphql-tag';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_CATEGORY_CHILDREN_MODEL from '@/graphql/wordpress/_fragments/fragmentCategoryChildrenModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const QUERY_GET_MAGAZINE_MAIN_ARTICLES = gql`
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_CATEGORY_CHILDREN_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  query getMagazineMainArticles($termId: ID!) {
    magazine(id: $termId) {
      posts(first: 6, where: { mainArticle: true }) {
        nodes {
          paywall {
            premium
            accessible
          }
          ...ContentNodeBasicModel
          title
          featuredImage {
            node {
              ...FeaturedImageModel
            }
          }
          extra {
            main_article
          }
          categories {
            nodes {
              ...CategoryChildrenModel
            }
          }
        }
      }
      pages(first: 6, where: { mainArticle: true }) {
        nodes {
          paywall {
            premium
            accessible
          }
          ...ContentNodeBasicModel
          title
          featuredImage {
            node {
              ...FeaturedImageModel
            }
          }
          categories {
            nodes {
              ...CategoryChildrenModel
            }
          }
        }
      }
    }
  }
`;

export default QUERY_GET_MAGAZINE_MAIN_ARTICLES;
