import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Money from '@/browser/Money';
import { getMinMaxPrice } from './PrisjaktUtil';

/**
 * Styled component for displaying a title.
 */
const Title = styled.div`
  font-size: 11px;
  letter-spacing: 1.4px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grey};
  margin-bottom: 5px;
  text-align: center;
`;

/**
 * Styled component for displaying price information.
 */
const Price = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space between;
  align-items: center;
  width: 100%;
`;

/**
 * Styled component for displaying a message.
 */
const Message = styled.div`
  display: flex;
  flex: ${(props) => (props.hasDefaultPrice ? 'inherit' : 'auto')};
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.grey};
  font-size: ${(props) => (props.$hasDefaultPrice ? '11px' : '16px')};
  text-transform: ${(props) => (props.$hasDefaultPrice ? 'uppercase' : 'none')};
  opacity: ${(props) => (props.$hasDefaultPrice ? '1' : '0.7')};
`;

/**
 * Styled component for displaying a small informational message.
 */
const SmallMessage = styled.div`
  font-size: 12px;
  font-weight: normal;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.grey};
`;

/**
 * PrisjaktPrice component that displays price information.
 * @param {Object} props - The component's props.
 */
const PrisjaktPrice = ({ productId, defaultPrice, prisjaktData }) => {
  const priceArray = getMinMaxPrice(productId, prisjaktData);

  if (Array.isArray(priceArray) && priceArray.length) {
    return (
      <span>
        {priceArray.length > 1 &&
        priceArray[0] !== priceArray[priceArray.length - 1] ? (
          <>
            <Money value={priceArray[0]} digits={2} />
            {' - '}
            <Money value={priceArray[priceArray.length - 1]} digits={2} />
          </>
        ) : (
          <Money value={priceArray[0]} digits={2} />
        )}
      </span>
    );
  } else if (defaultPrice > 0) {
    return (
      <Price>
        <Message $hasDefaultPrice>
          <FormattedMessage id="productReview.text.noUpdatedPrice" el="span" />
        </Message>
      </Price>
    );
  } else {
    return (
      <div>
        <SmallMessage>
          <FormattedMessage id="productReview.text.noUpdatedPrice" el="span" />
        </SmallMessage>
      </div>
    );
  }
};

/**
 * Prop types for the PrisjaktPrice component.
 */
PrisjaktPrice.propTypes = {
  productId: PropTypes.string.isRequired,
  defaultPrice: PropTypes.string.isRequired,
  prisjaktData: PropTypes.object
};

/**
 * Connects the PrisjaktPrice component to the Redux store and provides it with Prisjakt data.
 */
export default connect((state) => ({ prisjaktData: state.prisjakt }))(
  PrisjaktPrice
);
