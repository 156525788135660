import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Content from '@/browser/Content';
import WpImage from '@/components/Images/WpImage';

// Styled component for the container
const Container = styled.div`
  max-width: 720px;
  width: 95%;
  margin: 0 auto 60px auto;
  padding: 3em 2.2em;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[10]};

  small {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    color: ${(props) => props.theme.colors.grey};
    font-weight: 900;
    margin-bottom: 22px;
    display: block;
  }

  h2 {
    margin-bottom: 8px;
  }
`;

/**
 * React component for displaying a substory.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.title - The title of the substory.
 * @param {string} props.label - The label of the substory.
 * @param {Object} props.image - The image of the substory.
 * @param {string} props.content - The content of the substory.
 * @returns {JSX.Element} React component.
 */
const Substory = (props) => {
  // If necessary props are missing, return null
  if (!props.title && !props.content && !props.label && !props.image) {
    return null;
  }

  return (
    <Container>
      {props.title && <h2>{props.title}</h2>}
      {props.label && <small>{props.label}</small>}
      {props.image && <WpImage image={props.image} />}
      {props.content && <Content html={props.content} />}
    </Container>
  );
};

// PropTypes for type-checking
Substory.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  image: PropTypes.object,
  content: PropTypes.string
};

export default Substory;
